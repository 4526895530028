// Icon Menu
.nav > li > a:hover, .nav > li > a:focus,.nav > li.active > a {
	background-color: transparent!important;
}
.icon-menu{
	.menuzord .menuzord-menu > li > a:hover, .menuzord .menuzord-menu > li.active > a{
		color: #111;
	}
	.nav > li > a:hover, .nav > li > a:focus,.nav > li.active > a{
		background-color: transparent;
	}
	ul{
		padding-top:0;
	}
	li{
		a{
			transition: all 0.2s ease-in-out;
			&:hover{
				color: $accent;
			  i{
			    color: $accent;
			  }
			}
			span{
				margin-top: 0;
			}
			i{
				display: block;
				text-align: center;
				font-size: 16px;
				margin-bottom: 10px;
				line-height: 0;
			}
		}

		&.active{
			i{
				color: $accent;
			}
			span{
				background-color: transparent !important;
				color: $accent;
				font-weight: 600;
			}
		}
	}
}



ul.top-links,ul.top-social,ul.top-contact-links{
	margin-bottom: 0;
}

ul.top-links li,ul.top-social li,ul.top-contact-links li{
	display: block;
	float: left;
	width:auto;
	
	@media (min-width: 992px){
		border-right: 1px solid rgba(0,0,0,0.1);

		&:last-child{
			border-right:none;
		}
	}

	a{
		display: block;
		padding: 0 6px;
		line-height: 50px;
		text-transform: uppercase;
		color: $color__primary--light;
		letter-spacing: 1px;
		font-weight: 600;
		font-size: $text__size--epsilon;
		text-decoration: none;

		&:hover{
			color: $accent;
		}

		@media (min-width: 992px){
			padding: 0 15px;
		}
	}
}

ul.top-links{
	border-bottom: 1px solid rgba(0,0,0,0.1);
	@media (min-width: 992px){
		border-bottom: none;
		float: left;
	}
}

ul.top-contact-links{
	li{
		border-bottom: 1px solid rgba(0,0,0,0.1);
		display: block;
		width: 100%;

		@media (min-width: 992px){
			border-bottom: none;
			display: inline-block;
			width: auto;
		}
	}
	@media (min-width: 992px){
		float: right;
	}
}

ul.top-social li{
	a{
		font-size: $text__size--eta;
		font-weight: normal;
		color: $color__primary--light;
	}
}

ul.top-contact-links li{
	color: $color__primary--light;
	position: relative;
	display: block;
	float: left;
	width:auto;
	padding: 0 6px;
	line-height: 50px;
	text-transform: uppercase;
	color: $color__primary--light;
	letter-spacing: 1px;
	font-weight: 600;
	font-size: $text__size--epsilon;

	span.contact-link-text{
		display: inline-block;
		margin-left: 25px;
		line-height: 44px;
	}

	span.contact-link-icons{
		position: absolute;
		top:50%;
		transform: translate3d(0,-50%,0);
		font-size: $text__size--delta;
		color: $accent;
	}
}

.contact-link-icons{
	margin-right: 10px;
}

//*********************//
//   overlay menu
//********************//


/* Overlay style */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
	z-index: 9999;
}

/* Overlay closing cross */
.overlay .overlay-close {
	width: 80px;
	height: 80px;
	position: absolute;
	right: 20px;
	top: 20px;
	overflow: hidden;
	border: none;
	background: url(../images/header/cross.png) no-repeat center center;
	text-indent: 200%;
	color: transparent;
	outline: none;
	z-index: 100;
}

/* Menu style */
.overlay nav {
	text-align: center;
	position: relative;
	top: 50%;
	height: 60%;
	font-size: 54px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: 100%;
	position: relative;
}

.overlay ul li {
	display: block;
	height: 20%;
	height: calc(100% / 5);
	min-height: 54px;
}

.overlay ul li a {
	font-weight: 300;
	display: block;
	color: $white;
	-webkit-transition: color 0.2s;
	transition: all 0.4s;
}

.overlay ul li a:hover,
.overlay ul li a:focus {
	text-decoration: none;
	color: $accent;
}

/* Effects */
.overlay-hugeinc {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
}

.overlay-hugeinc.open {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.overlay-hugeinc nav {
	-webkit-perspective: 1200px;
	perspective: 1200px;
}

.overlay-hugeinc nav ul {
	opacity: 0.4;
	-webkit-transform: translateY(-25%) rotateX(35deg);
	transform: translateY(-25%) rotateX(35deg);
	-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
	transition: transform 0.5s, opacity 0.5s;
}

.overlay-hugeinc.open nav ul {
	opacity: 1;
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.overlay-hugeinc.close nav ul {
	-webkit-transform: translateY(25%) rotateX(-35deg);
	transform: translateY(25%) rotateX(-35deg);
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}

.overlay-menu-wrap{
	position: relative;
	position: fixed;
	z-index: 999;
	width: 100%;

	// &.navbar-solid a.mountain-logo{
	// 	padding: 10px 0
	// }

	.mountain-logo img{
		width: 80px;
	}

	.container{
		position: relative;
	}

	a.mountain-logo{
		transition: all 0.4s;
		padding: 20px 0;
		float: left;
	}
}

.trigger-overlay{
	position: absolute;
	top:50%;
	transform:translateY(-50%);
	right: 0;
	margin: 0;
	border-radius: 1px;
	border: none;
	background-color:transparent;
	color: #fff;

	i{
		font-size: 24px;
		line-height: 1;
		display: block;
	}
}




// Modified by Humayun

.openmenu {
    display: block !important;
}

// Menuzord Style
.menuzord{
	z-index: 999;
	text-transform: uppercase;
	background-color: transparent;
	letter-spacing: 1px;

	@media (max-width: 767px){
		background: black;
	}
		
	// Common style

	.menuzord-brand{
		margin: 11px 30px 0 0;
		
		img{
			width: 80%;
		}
		.default-logo{
			display: block;
		}
		.dark-logo{
			display: none;
		}
	}
	span.new{
	  	background-color: $accent;
	  	margin-left: 20px;
	  	font-weight: 700;
		line-height: 1.6;
		font-size: 10px;
		padding: 0 6px;
		right: 15px;
		top: 0;
	}

	.menuzord-menu > li > a{
		color: $white;
		font-size: 11px;
		font-family: $alt_font;
		font-weight: 400;

		&:hover, &:active{
			color: #cdcdcd;
		}
		.indicator{
			font-family: 'Ionicons';
			padding-left: 5px;
		}
	}

	// Dropdown Style

	.menuzord-menu{
		.dropdown{
			background-color: #0d0d0d !important;
			border-top: 1px solid rgba(134, 134, 134, 0.5);;
			padding-top: 15px;

			li:hover > a{
				color: $white;
			}
			a{
				font-family: $alt_font;
				padding: 10px 25px;
				font-size: 11px;
				position: relative;

				span.new{
					color: $white;
				}

				&:hover, &:active{
					color: $white;
					opacity: 1;
				}
			}
		}
	}

	// Megamenu Style

	@media (max-width: 1200px){
		.menuzord-menu > li > .megamenu.megamenu-quarter-width{
			width: 33.33%;
		}
	}
	@media (max-width: 991px){
		.menuzord-menu > li > .megamenu.megamenu-half-width{
			width: 100%;
		}
		.menuzord-menu > li > .megamenu.megamenu-quarter-width{
			width: 50%;
		}
	}
	.menuzord-menu > li > .megamenu{
		background-color: #0d0d0d;
		border-top-color: rgba(134, 134, 134, 0.5);
		padding: 30px 30px 0 30px;

		@media (min-width: 768px){
			min-width: 450px;
		}

		ul{
			padding-top: 0;
			margin-bottom: 30px;
		}
		h4{
			color: $white;
		    margin-top: -6px;
		    padding-bottom: 3px;
		    font-size: 16px;
		    margin-bottom: 20px;
		    font-weight: 700;
		    border-bottom: 1px solid rgba(134, 134, 134, 0.5);
		}
		h5{
			color: $white;
			font-size: 11px;
			margin-bottom: 15px;
			font-family: $alt_font;
			position: relative;
		}
		a{
			color: #828282;
			padding: 7px 0;
			display: block;
			font-size: 11px;
			font-family: $alt_font;
			@include smoothTransition();

			span.new{
				color: $white;
			}

			&:hover{
				text-decoration: none;
				color: $white;
				padding-left: 5px;
			}
		}
	}
}
@media (max-width: 768px){
	.menuzord-responsive{

		.menuzord-menu > li > a{
			border-bottom-color: #626262;
			// color: #111;
			color: #fff;
		}
		.menuzord-menu li .indicator{
			border-left: none;
			width: 100%;
			right: 0;
			text-align: right;
			padding-right: 22px;
		}
		.menuzord-menu.scrollable {
		    // max-height: 100% !important;
		}
		.menuzord-menu ul.dropdown li a {
			border-bottom: 1px solid transparent;
		}
	}

	.menuzord .menuzord-menu > li > .megamenu a {
		padding: 10px 0px;
	}
}

.menuzord-responsive .showhide{
	padding: 20px 0 0;
}
@media (max-width: 768px){
	.menuzord-responsive .menuzord-brand {
	    margin: 8px 30px 10px 20px;
	}
	.menuzord {
		float: none;
		margin-bottom: 0 !important;
		overflow: hidden;
	}
}

// Custom Menuzord style

.menuzord--light{
	background-color: $white;

	.menuzord-menu > li > a{
		color: $color__primary--dark;

		&:hover, &:active{
			color: $color__primary--dark*9;
		}
	}
	.menuzord-menu > li.active > a {
		color: $color__primary--dark*9;
	}
}
.navigationbar--fixed--top{
	position: fixed;
	top: 0;
	transform: translate3d(0, -120px, 0);
	// box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
	box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
}
.navigationbar--fixed--top--transition{
	transition: transform .5s ease-in-out;
}
.navigationbar--fixed--top--animate{
	transform: translate3d(0, 0px, 0);
}


// Dot Menu
.dot-menu{
	.menuzord{
		background-color: transparent;
		box-shadow: none;

		.menuzord-menu{
			li{
				a{
					span{
						padding: 3px 7px;
						color: $white;
					}
				}
			}
		}
	}
}

.split-cover-nav {
	.menuzord {
		background-color: #fff;
		border-bottom: 1px solid #f2f3f5;
		.menuzord-brand {
			.default-logo {
				display: none !important;
			}
			.dark-logo {
				display: block !important;
			}
		}
		.menuzord-menu > li > a {
			color: #000;
		}
	}
}








// Helper class
.solid{
	background-color: $color__primary--dark;
	transition: all 0.4s ease-in-out;
}